import React from "react";
import { FaTwitter, FaInstagram, FaLinkedin, FaYoutube, FaWhatsapp } from 'react-icons/fa';

import "./footer.scss";
import useWindowSize from "../states/useWindowSize";

import location from "../../assets/img/location.png";
import soaresLogoFooter from "../../assets/img/soares-logo-footer.png";
import telefone from "../../assets/img/telefone.png";
import email from "../../assets/img/email.png";
import selo from "../../assets/img/selo.png";


export default function Footer() {
    const { width, height } = useWindowSize();

    function handleRedirectSocialMedia(link) {
        window.open(link);
    }

    return (
        <footer className="footer">
            <div className="footer__content" style={{width: width}}>
                <div className="footer__content_wd">
                    <div className="footer__left">
                        <div className="item">
                            <img src={location} alt="Localização" />
                            <p>Rua Giovanni Battista Pirelli, 271 - Vila Homero Thon, Santo André - SP - 09111-340</p>
                        </div>
                        <div className="item">
                            <img src={email} alt="Localização" />
                            <p>comercial@soaresconsulting.com.br</p>
                        </div>
                        <div className="item">
                            <img src={telefone} alt="Localização" />
                            <p>(11) 98745-4673</p>
                        </div>
                    </div>
                    <div className="footer__right">
                        <img src={soaresLogoFooter} className="logoFooter" alt="Logo Soares" />
                        <div className="footer__right__social">
                            <FaYoutube className="icon-socialmedia" onClick={() => handleRedirectSocialMedia('https://www.youtube.com/@soaresconsulting')} style={{color: 'white', fontSize: '24px'}} />
                            <FaWhatsapp className="icon-socialmedia" onClick={() => handleRedirectSocialMedia('https://api.whatsapp.com/send?phone=5511987454673&text=Ol%C3%A1%2C%20tudo%20bem%3F')} style={{color: 'white', fontSize: '24px'}} />
                            <FaInstagram className="icon-socialmedia" onClick={() => handleRedirectSocialMedia('https://www.instagram.com/soaresconsulting/')} style={{color: 'white', fontSize: '24px'}} />
                            <FaLinkedin className="icon-socialmedia" onClick={() => handleRedirectSocialMedia('https://www.linkedin.com/company/soares-consulting')} style={{color: 'white', fontSize: '24px'}} />
                        </div>
                    </div>
                </div>
                <img className="seloMicrosoft" src={selo} alt="Selo" />
            </div>
        </footer>
    );
}
