import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';

import "./diferenciaisSection.scss";
import relatorios from "../../assets/img/relatorios.png";
import grupos from "../../assets/img/grupos.png";
import filtros from "../../assets/img/filtros.png";

import useWindowSize from "../states/useWindowSize";

const FadeInSection = ({ children }) => {
    const { ref, inView, entry } = useInView({
      threshold: 0.15,
    });
  
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      if (inView) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, [inView]);
  
    return (
      <div ref={ref} className={`fade-in-section ${isVisible ? 'visible' : ''}`}>
        {children}
      </div>
    );
};

export default function DiferenciaisSection() {
    const { width, height } = useWindowSize();

    function handleRedirectOrcamento() {
      window.open("https://api.whatsapp.com/send?phone=5511987454673&amp;text=Ol%C3%A1%2C%20tudo%20bem%3F");
    }

    return (
        <section className="diferenciais-section">
            <div className="background-diferenciais-section" style={{width: width}}>
                <FadeInSection>
                  <h2>Nossos Diferenciais</h2>
                </FadeInSection>
                <FadeInSection>
                  <div className="diferenciais-section__content">
                      <div className="diferenciais-section__content__item">
                        <img src={relatorios} alt="Diferencial 1" />
                        <div className="diferenciais-section__content__item__texts">
                          <div className="listra"></div>
                          <h3>Menor Custo</h3>
                          <p>Com o SCTech Embedded, você pode economizar até 6x por usuário em comparação com Power BI<br/><br/>
                            Utilizando o licenciamento por capacidade, os usuários ​que apenas visualizam os relatórios podem acessá-los 
                            ​através do SCTech Embedded, eliminando a ​necessidade de uma licença Pro do Power BI. Isso ​permite que 
                            mais pessoas visualizem os relatórios sem ​aumentar os custos mensais com licenciamento.
                          </p>
                          <button onClick={handleRedirectOrcamento} className="btn-diferencial-item">Solicite um orçamento</button>
                        </div>
                      </div>
                  </div>
                </FadeInSection>
                <FadeInSection>
                  <div className="diferenciais-section__content">
                      <div className="diferenciais-section__content__item">
                        <div className="diferenciais-section__content__item__texts">
                          <div className="listra"></div>
                          <h3>Controle e administração</h3>
                          <p>
                            Nosso portal oferece um painel de administração ​completo, proporcionando total controle sobre usuários, 
                            ​grupos, permissões, pastas, RLS, relatórios importados, ​personalização no setup e muito mais.
                          </p>
                          <button onClick={handleRedirectOrcamento} className="btn-diferencial-item">Solicite um orçamento</button>
                        </div>
                        <img src={filtros} alt="Diferencial 2" />
                      </div>
                  </div>
                </FadeInSection>
                <FadeInSection>
                  <div className="diferenciais-section__content last-diferenciais">
                      <div className="diferenciais-section__content__item">
                          <img src={grupos} alt="Diferencial 1" />
                          <div className="diferenciais-section__content__item__texts">
                              <div className="listra"></div>
                              <h3>Outros benefícios</h3>
                              <ul className="diferenciais-section__content__item__list">
                                  <li>Plataforma personalizável (gratuito)</li>
                                  <li>Custo por usuário até 6x menor do que o Power BI</li>
                                  <li>Setup inicial geral realizado por nossa equipe</li>
                                  <li>Restrição de acessos de usuário por Hashes únicos ​(mais efetivo que endereço IP)</li>
                                  <li>Gerenciamento de usuários que possuem acesso a ​um grupo ou dashboard específico</li>
                                  <li>Adição de filtros para restringir informações ​específicas para cada usuário em um relatório RLS</li>
                                  <li>Segurança contra bots e acessos indevidos</li>
                                  <li>E muito mais...</li>
                              </ul>
                              <button onClick={handleRedirectOrcamento} className="btn-diferencial-item">Solicite um orçamento</button>
                          </div>
                      </div>
                  </div>
              </FadeInSection>
            </div>
        </section>
    );
}
