import { useState, useEffect } from 'react';

function useInitialWindowSize() {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // A única vez que precisamos definir isso é na montagem do componente
  useEffect(() => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  return screenSize;
}

export default useInitialWindowSize;
