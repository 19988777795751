import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';

import item1 from "../../assets/img/item-1.png";
import item2 from "../../assets/img/item-2.png";
import item3 from "../../assets/img/item-3.png";
import notebookRelatorios from "../../assets/img/notebook-relatorios.png";
import notebookRelatorio from "../../assets/img/notebook-relatorio.png";
import setas from "../../assets/img/setas.png";
import tabletMockup from "../../assets/img/mockup-tablet.png";
import "./learnAboutSection.scss";

import useWindowSize from "../states/useWindowSize";

const FadeInSection = ({ children }) => {
    const { ref, inView, entry } = useInView({
      threshold: 0.1,
    });
  
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      if (inView) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, [inView]);
  
    return (
      <div ref={ref} className={`fade-in-section ${isVisible ? 'visible' : ''}`}>
        {children}
      </div>
    );
};

export default function LearnAboutSection() {
  const { width, height } = useWindowSize();

  function handleRedirectOrcamento() {
    window.open("https://api.whatsapp.com/send?phone=5511987454673&amp;text=Ol%C3%A1%2C%20tudo%20bem%3F");
  }

  return (
    <section className="learn-about-section" style={{ width: width }}>
        <FadeInSection>
          <h2>Prepare-se para revolucionar a gestão da sua empresa ​com sua plataforma de visualização de dashboards Embedded em Power BI!</h2>
          <p>Conheça sobre a plataforma:</p>
        </FadeInSection>
      <div className="learn-about-section__content">
        <div className="left-content">
          <FadeInSection>
            <div className="left-content-proposta">
              <h3>O que nossa plataforma oferece?</h3>
              <p>O SCTech Embedded é uma solução SaaS ​(Software as a Service) da Soares Consulting que ​utiliza uma licença por capacidade do Fabric ou ​Power BI Embedded para acessar as APIs oficiais ​do Power BI e exibir relatórios publicados em um ​portal totalmente personalizado.</p>
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className="images-content">
              <img src={notebookRelatorios} className="notebookRelatorios" alt="notebookRelatorios" />
              <img src={setas} className="setas" alt="setas" />
              <img src={notebookRelatorio} className="notebookRelatorios" alt="notebookRelatorio" />
            </div>
          </FadeInSection>
        </div>
        <div className="right-content">
          <div className="right-content-items">
            <FadeInSection>
              <div className="items item-first">
                <div className="item__image__title">
                  <img src={item1} alt="item1" />
                  <h3>Visualize ​Dashboards</h3>
                </div>
                <p>Você pode visualizar todos seus ​relatórios na plataforma em tela ​cheia, com uma experiência fácil e ​intuitiva.</p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="items">
                <div className="item__image__title">
                  <img src={item2} alt="item1" />
                  <h3>Gerencie os ​relatórios de ​seus clientes</h3>
                </div>
                <p>Se sua demanda é alocar dashboards ​de clientes, criando e gerenciando ​usuários e lhe disponibilizar relatórios ​com ou sem filtros, você pode receber ​um usuário administrador conectado ​as suas contas Power BI. Todo setup é ​feito por nossa equipe!</p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="items item-last">
                <div className="item__image__title">
                  <img src={item3} alt="item1" />
                  <h3>Personalize do ​seu jeito</h3>
                </div>
                <p>Você pode personalizar a plataforma ​com a identidade visual de sua ​empresa, alterando cores, imagens, ​textos e domínio (url).</p>
              </div>
            </FadeInSection>
          </div>
          <FadeInSection>
            <button onClick={handleRedirectOrcamento}>Solicite um orçamento</button>
          </FadeInSection>
        </div>
      </div>
      <div className="video-plataforma">
          <div className="video-plataforma__content">
            <h3>Conheça a plataforma:</h3>
            <iframe height={height * 0.47} src="https://www.youtube.com/embed/2e9mVGpqoIc?si=qORJzGeFRlunU7y8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <img className="mockupTablet" src={tabletMockup} alt="tabletMockup" />
      </div>
    </section>
  );
}